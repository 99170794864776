// extracted by mini-css-extract-plugin
export var herohome = "index-module--herohome--1EBOq";
export var heroside = "index-module--heroside--3IX94";
export var herosection = "index-module--herosection--IZXDy";
export var herosectionmap = "index-module--herosectionmap--2Pqn8";
export var heroa = "index-module--heroa--1-X8O";
export var headerfont = "index-module--headerfont--1adzG";
export var headerfonta = "index-module--headerfonta--1cu3S";
export var herobuttom = "index-module--herobuttom--3xJvw";
export var buttomflex = "index-module--buttomflex--2BlXT";
export var colservice = "index-module--colservice--21fHl";
export var headerfontb = "index-module--headerfontb--2KaGg";
export var buttomcategory = "index-module--buttomcategory--28rxB";
export var colproduct = "index-module--colproduct--STRSy";
export var imgproduct = "index-module--imgproduct--3EKVT";
export var buttomdownload = "index-module--buttomdownload--ekr1I";
export var section = "index-module--section--2bLxw";
export var headerb = "index-module--headerb--2szJ2";
export var headerdiv = "index-module--headerdiv--1LDEW";
export var herobuttoma = "index-module--herobuttoma--2hgCp";
export var herobuttomb = "index-module--herobuttomb--2YbOZ";
export var headera = "index-module--headera--XExh7";
export var par = "index-module--par--jyvz5";
export var contactflex = "index-module--contactflex--i2CJ5";
export var plive = "index-module--plive--3orlL";
export var plivedes = "index-module--plivedes--3Hhp6";
export var psocial = "index-module--psocial--1auCK";
export var psocialfacebook = "index-module--psocialfacebook--3EYQg";
export var psocialline = "index-module--psocialline--2aUlS";
export var hcat = "index-module--hcat--2iqVl";
export var buttomcat = "index-module--buttomcat--Ovw2e";
export var buttomdown = "index-module--buttomdown--1xols";
export var buttomproduct = "index-module--buttomproduct--3TzG4";