import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as garmentStyles from "../css/garment.module.css"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Bgindustrial from "../components/bgindustrial"
import Seo from "../components/seo"

const SeoTitle = "ด้ายอุตสาหกรรม"
const SeoDecription = "ด้ายอุตสาหกรรม เราผู้ผลิตนำเข้าและจำหน่ายมาตาฐานสูง สินค้าของเราแข็งแรง ทดทาน และมีคุณภาพมากมายที่เหมาะต่ออุตสาหกรรมการผลิตเส้นด้าย"
const url = "/industrial-thread/"

export default function industrial({data}) {
    return (
    <Containers>
      <Seo 
        title={SeoTitle}
        description={SeoDecription}
        pathname={url}
       />
      <Headertop />
      <Header />
      <Bgindustrial />
      <div className={garmentStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  Product</p></Col>
              </Row>
      </Container>
      </div>
      <div className={garmentStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={3}>
                <div className={garmentStyles.categories}>
                  <h3>หมวดหมู่สินค้า</h3>
                  <Link to="/categories/"><p className={garmentStyles.pmenu}>สินค้าทั้งหมด</p></Link>
                  <Link to="/garment/"><p className={garmentStyles.pmenu}>งานการ์เม้นท์</p></Link>
                  <Link to="/sew-a-sack/"><p className={garmentStyles.pmenu}>ด้ายเย็บกระสอบ</p></Link>
                  <Link to="/industrial-thread/"><p className={garmentStyles.pmenuactive}>ด้ายอุตสาหกรรม</p></Link>
                  <Link to="/product-import/"><p className={garmentStyles.pmenu}>สินค้านำเข้า</p></Link>
                  <Link to="/other-products/"><p className={garmentStyles.pmenu}>สินค้าอื่นๆ</p></Link>
                </div>
                </Col>
                <Col md={9}>
                  <Containers>
                    <Row>
                      {data.allMarkdownRemark.edges.map(({ node }) => (
                        <Col xs={6} md={4} key={node.id} className={homeStyles.colproduct}>
                          <Link to={node.fields.slug}>
                            <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} className={homeStyles.imgproduct} />
                            <h3>{node.frontmatter.title}{" "}</h3>
                          </Link>
                        </Col>))}
                    </Row>
                  </Containers>
                </Col>
              </Row>
          </Container>
      </div>
        <Container className="pb-5">
          <Row>
            <Col md={12}>
              <p>ด้ายอุตสาหกรรมคือวัสดุที่ใช้ในการทำเส้นใยหรือเป็นลวดแบบยาวที่มีความแข็งแรงและทนทาน เพื่อนำไปใช้ในการผลิตผลิตภัณฑ์ต่าง ๆ ในอุตสาหกรรมต่าง ๆ รวมทั้งอุตสาหกรรมผ้าทอ อุตสาหกรรมเครื่องแต่งกาย อุตสาหกรรมยางและพลาสติก อุตสาหกรรมสายไฟ และอื่น ๆ</p>
              <h2>ด้ายอุตสาหกรรม สามารถทำจากวัสดุต่างๆได้หลายแบบ</h2>
              <p>ด้ายอุตสาหกรรมสามารถทำจากวัสดุต่าง ๆ อย่างไรก็ตาม มีหลายประเภทขึ้นอยู่กับการใช้งานและคุณลักษณะที่ต้องการ บางประเภทของด้ายอุตสาหกรรมทำจากใยธรรมชาติ เช่น ไหม ฝ้าย หรือแก้ว ในขณะที่อื่น ๆ อาจทำจากวัสดุเทียมเท่านั้น เช่น พอลีเอสเตอร์ หรือไนลอน</p>
              <p>การเลือกใช้ด้ายอุตสาหกรรมที่เหมาะสมมีความสำคัญอย่างมากเนื่องจากมีผลต่อคุณภาพของผลิตภัณฑ์และประสิทธิภาพในการผลิต ตลาดด้ายอุตสาหกรรมมีหลายรูปแบบและคุณสมบัติที่ต่างกัน เช่น ด้ายที่มีความแข็งแรงสูงมักถูกนำมาใช้ในงานที่ต้องการความทนทาน ในขณะที่ด้ายที่ยืดหยุ่นมากจะเหมาะสำหรับงานที่ต้องการความยืดหยุ่น เช่น ในอุตสาหกรรมเครื่องแต่งกายและส่วนต่อประสานต่าง ๆ ในงานท่องเที่ยว ด้ายอุตสาหกรรมเลือกใช้ตามความต้องการของแต่ละงานเพื่อให้ได้ผลิตภัณฑ์ที่มีคุณภาพและความเหมาะสมสูงสุด</p>
              <h3>ด้ายอุตสาหกรรมนำไปใช้ในผลิตภัณฑ์ หลายประการที่สามารถแบ่งได้เป็นหลายกลุ่มตามลักษณะและคุณสมบัติของด้าย ดังนี้:</h3>
              <ol>
                <li>เสื้อผ้าและสิ่งทอ: ด้ายอุตสาหกรรมเป็นส่วนสำคัญของอุตสาหกรรมเสื้อผ้าและสิ่งทอ ใช้ในการทำผ้าและสายในการทอผ้าที่มีความหลากหลายทั้งในเนื้อผ้าและลวดทอ</li>
                <li>เครื่องแต่งกาย: ในอุตสาหกรรมเครื่องแต่งกาย, ด้ายอุตสาหกรรมมักถูกนำมาใช้ในการเย็บเสื้อ, กางเกง, กระโปรง, และผลิตภัณฑ์เครื่องประดับ</li>
                <li>อุตสาหกรรมรองเท้า: ในการผลิตรองเท้า, ด้ายอุตสาหกรรมใช้ในการเย็บสายรองเท้าและในกระบวนการผลิตรองเท้าที่มีการใช้หลายวัสดุรวมกัน</li>
                <li>อุตสาหกรรมถังน้ำ, ถุงยาง: ในอุตสาหกรรมที่ใช้ถังน้ำหรือถุงยาง, ด้ายอุตสาหกรรมมักถูกนำมาใช้ในกระบวนการผลิตเพื่อเชื่อมต่อและทำให้ผลิตภัณฑ์มีความแข็งแรง</li>
                <li>อุตสาหกรรมเครื่องใช้ไฟฟ้า: ในการผลิตเครื่องใช้ไฟฟ้า, ด้ายอุตสาหกรรมมักถูกนำมาใช้ในส่วนของสายไฟและการต่อเชื่อม</li>
                <li>อุตสาหกรรมยางและพลาสติก: ในอุตสาหกรรมนี้, ด้ายอุตสาหกรรมใช้เพื่อผลิตสายไหม, สายพาน, และสินค้ายางและพลาสติกอื่น ๆ</li>
                <li>อุตสาหกรรมยุติธรรม: ด้ายอุตสาหกรรมมักถูกนำมาใช้ในการผลิตเชือก, ลวดตีเหล็ก, และวัสดุที่ใช้ในงานก่อสร้าง</li>
                <li>การตกแต่งภายใน: ในงานตกแต่งภายใน, ด้ายอุตสาหกรรมมักใช้ในการทำมุ้ง, ผ้าม่าน, และงานตกแต่งภายในทั่วไป</li>
              </ol>
              <p>การเลือกใช้ด้ายอุตสาหกรรมขึ้นอยู่กับคุณสมบัติที่ต้องการในผลิตภัณฑ์ที่กำลังผลิต, และอุตสาหกรรมที่เกี่ยวข้อง</p>
              <p>ด้ายอุตสาหกรรม โพลีเอสเตอร์ ผลิตจากวัสดุพลาสติกที่สามารถทำให้เกิดเส้นใยหรือลวดอย่างยืดหยุ่นและแข็งแรง ด้ายโพลีเอสเตอร์มีลักษณะทนทานต่อการได้รับแสงแดด, ความแข็งแรงสูง, และไม่เสียประสิทธิภาพเมื่อเปียก</p>
              <p>นอกจากนี้ ด้ายโพลีเอสเตอร์มีคุณสมบัติที่ทนทานต่อสภาพแวดล้อมต่าง ๆ รวมถึงการทนทานต่อสารเคมี, การทนทานต่อการเปียกน้ำ, และการแก้วหรือบดบังต่อการทำลายจากเชื้อราและแบคทีเรีย</p>
            </Col>
          </Row>
        </Container>
      <Formfooter />
      <Footer />
    </Containers>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "industrial-thread" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`